import React from 'react'
import { Container, Row, Button, Col, Image } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

function PirceList() {
    const pageTitle = 'Cennik'

    return (
        <div>
            <Container className='' style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                <Row>
                    <Col xl={6}>
                        <div className='grid'>
                            <figure class="effect-sadie">
                                <img src="img/tiles/konsultacje.jpg" alt="Konsultacje" />
                                <figcaption>
                                    <h2><span>Konsultacje 1:1</span></h2>
                                    <p>250 zł – na sali (60 minut)<br></br>
                                        200 zł – online (60 minut) <br></br>
                                        W tym bezpłatna konsultacja online (30 minut)
                                        <br></br>
                                        <LinkContainer to='/kontakt'>
                                            <Button className='btn2 draw-border mt-2'>Kontakt</Button>
                                        </LinkContainer>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div className='grid'>
                            <figure class="effect-sadie">
                                <img src="img/tiles/3.jpg" alt="Trening Indywidualny" />
                                <figcaption>
                                    <h2><span>Trening Indywidualny</span></h2>
                                    <p>250 zł – na sali (60 minut)<br></br>
                                        200 zł – online (60 minut) <br></br>
                                        W tym bezpłatna konsultacja online (30 minut)
                                        <br></br>
                                        <LinkContainer to='/zajecia-indywidualne'>
                                            <Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button>
                                        </LinkContainer>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div className='grid'>
                            <figure class="effect-sadie">
                                <img src="img/tiles/zestawy-cwiczen.jpg" alt="zestawy-cwiczen" />
                                <figcaption>
                                <h2>Kurs online <br></br> <span>"Zrób w końcu szpagat"</span></h2>
                                    <p>279 zł<br></br>
                                        <br></br>
                                        <br></br>
                                        <a href='https://preview.mailerlite.io/preview/904647/sites/123848777195849025/LBKrw4' target='_blank'><Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button></a>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div className='grid'>
                            <figure class="effect-sadie">
                                <img src="img/tiles/zajecia-grupowe.jpg" alt="zajecia-grupowe" />
                                <figcaption>
                                    <h2><span>Zajęcia grupowe</span></h2>
                                    <p>Zgodnie z cennikiem
                                        <a className='ms-1 me-1' href='https://mdk.katowice.pl/joga-zajecia-dla-doroslych' target="_blank" style={{ color: '#e9c8b1' }}>MDK</a>
                                        i
                                        <a className='ms-1 me-1' href='http://www.klubsalsa.pl/' target="_blank" style={{ color: '#e9c8b1' }}>Fitness Klub Slasa </a>
                                        <br></br>
                                        <LinkContainer to='/grafik'><Button className='btn2 draw-border mt-2'>Grafik</Button></LinkContainer>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div className='grid'>
                            <figure class="effect-sadie">
                                <img src="img/tiles/warsztaty.jpg" alt="Warsztaty" />
                                <figcaption>
                                    <h2>WARSZTATY i <span>WYJAZDY</span></h2>
                                    <p>
                                        <LinkContainer to='/warsztaty'><Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button></LinkContainer></p>
                                </figcaption>
                            </figure>
                        </div>
                    </Col>
                    <Col xl={6}>
                        <div className='grid'>
                            <figure class="effect-sadie">
                                <img src="img/tiles/voucher.jpg" alt="Voucher" />
                                <figcaption>
                                    <h2><span>Voucher</span></h2>
                                    <p>Konsultacje 1:1 lub Trening indywidualny<br></br>
                                        250 zł – na sali (60 minut)<br></br>
                                        200 zł – online (60 minut) <br></br>
                                        W tym bezpłatna konsultacja online (30 minut)
                                        <br></br>
                                        <LinkContainer to='/voucher'>
                                            <Button className='btn2 draw-border mt-2'>Dowiedz się więcej</Button>
                                        </LinkContainer>
                                    </p>
                                </figcaption>
                            </figure>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PirceList
